/* Custom Scroll code starts here  */

/* ::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    
}

::-webkit-scrollbar
{
    width: 8px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #afafaf;
} */

.App {
	text-align: center;
	font-family: Poppins !important;
}

.button-primary {
	background-color: #4f677b;
	color: #fff;
}

.button-primary:hover {
	background-color: #4f677b;
}
.text-capitalise {
	text-transform: capitalize;
}
.grey-font {
	color: #8596a9;
}

.toggle-filters {
	margin-left: 6rem;
}

.d-none {
	display: none;
}

/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
	/* border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	background-color: #535b63;
	border-radius: 2px;
	color: #fff;
	text-align: center;
	font-size: 12px;
	padding: 8px 10px;
	position: absolute;
	z-index: 1;
	border-radius: 3px;
	left: 0;
	width: 230px;
	top: 25px;
}

.tooltip .tooltiptext::after {
	content: " ";
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #535b63;
	position: absolute;
	top: -5px;
	left: 0;
	right: 0;
	margin: auto;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
}

.common-container {
	/* padding: 20px 25px 0 25px; */
}

.common-container .stepper-steps .MuiStepper-root {
	padding: 20px 0;
	padding-top: 0px;
}

.stepper-steps {
	width: 100% !important;
}

.stepper-container {
	display: flex;
	justify-content: space-between;
}

.common-container .step-content {
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #c8ced7;
	border-radius: 3px;
	text-align: left;
	padding: 16px 30px;
}

.common-container .step-content .MuiPaper-root {
	padding-top: 0;
	padding-bottom: 0;
}

.common-container .step-content .MuiBox-root {
	margin: 0 auto;
}

.common-container .step-content .MuiGrid-root {
	margin-left: 0;
	gap: 17px 35px;
}

.common-container .step-content .accordion-content .MuiGrid-root.MuiGrid-item {
	padding: 0;
	max-width: 100%;
}

.common-container .step-content .accordion-content .MuiPaper-root {
	padding: 0;
}

.common-container .step-content .accordion-content .MuiPaper-root.filterGroup {
	padding: 0;
}

.common-container .step-content .MuiPaper-root.filterGroup label.filter-label {
	display: block;
	/* padding-bottom: 5px; */
	text-align: left;
	font-size: 12px;
	line-height: 26px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #758490;
}

.common-container
	.step-content
	.MuiPaper-root.filterGroup
	label.required::after {
	content: "*";
	color: red;
}

.common-container .step-content .MuiAccordion-root::before {
	content: "";
	background-color: transparent;
}

.common-container .step-submit-buttons {
	gap: 20px;
	margin-top: 15px;
}

.common-container .step-submit-buttons button {
	margin: 0;
	border-radius: 3px;
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	letter-spacing: 0px;
	height: 35px;
}

.common-container .step-submit-buttons button.clear-filter {
	font-size: 16px;
	line-height: 26px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #0055af;
	padding: 0;
}

.common-container .field-label {
	display: block;
	text-align: left;
	font-size: 12px;
	line-height: 26px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #758490;
}

.common-container .text-input .MuiInputBase-root {
	width: 350px;
	height: 35px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #acacac;
	border-radius: 3px;
	text-align: left;
	font-size: 14px;
	line-height: 26px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #1d1d1d;
}

.common-container .text-input .MuiInputBase-root input {
	padding: 8px 20px;
}

.common-container .text-input .MuiInputBase-root input:focus {
	border: 1px solid #0055af;
	box-shadow: #e5edf7 0px 0px 0px 3px;
	outline: none;
	height: 35px;
	box-sizing: border-box;
	border-radius: 3px;
}

.common-container .text-input fieldset {
	border: 0;
}

.common-container .input-select {
	width: 350px;
	height: 35px;
}

.common-container .accordion-content .filter-group-container .MuiGrid-item {
	max-width: 100%;
}

.common-container
	.accordion-content
	.filter-group-container
	.MuiGrid-item
	.dropdown-wrapper {
	width: 350px;
}

.grid-custom-content {
	display: flex;
	padding: 5px 0;
	justify-content: space-between;
	align-items: center;
}

.grid-custom-content .text-input-search .MuiInputBase-root {
	width: 300px;
	height: 35px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #acacac;
	border-radius: 3px;
	text-align: left;
	font-size: 14px;
	line-height: 26px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #1d1d1d;
}

.grid-custom-content .text-input-search .MuiInputBase-root input {
	padding: 8px 20px;
}
.grid-custom-content .text-input-search .MuiInputBase-root input:focus {
	border: 1px solid #0055af;
	box-shadow: #e5edf7 0px 0px 0px 3px;
	outline: none;
	height: 35px;
	box-sizing: border-box;
	border-radius: 3px;
}
.grid-custom-content .text-input-search fieldset {
	border: 0;
}

.table-extra-content-left {
	display: flex;
	gap: 20px;
}

.table-extra-content-left label.input-checkbox {
	margin: 0 !important;
	text-align: left;
	font-size: 14px;
	line-height: 26px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #1d1d1d !important;
}

.table-extra-content-left .input-checkbox .MuiCheckbox-root {
	padding: 0;
}

.table-extra-content-left .input-checkbox .MuiCheckbox-root svg {
	width: 16px;
	height: 16px;
	padding-right: 4px;
}

.table-exra-content-right {
	height: 35px;
	width: 72px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #acacac;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.table-exra-content-right svg {
	width: 16px;
	height: 16px;
	fill: #0055af;
	flex: 1 1 auto;
	cursor: pointer;
}

.table-exra-content-right svg:nth-of-type(1) {
	border-right: 1px solid #acacac;
}

.filter-summary-container.table-selection-summary {
	margin: 0;
}

.MuiSnackbar-root .MuiPaper-root {
	padding: 10px 15px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.firebaseui-idp-button {
	max-width: 180px !important;
}

.firebaseui-idp-button .firebaseui-idp-text {
	color: #475569;
	font-size: 0.875rem;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 1.5;
}

.login-form .MuiInput-root input {
	padding: 5px 0;
	font-weight: 400;
	letter-spacing: 0px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: rgb(29, 29, 29);
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	border-top: 1px solid #acacac;
	border-bottom: 1px solid #acacac;
	padding: 6px 20px !important;
	border-radius: 2px !important;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #1d1d1d;
}

/* header css */

h1 {
	text-decoration: none;
	text-align: left;
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #1d1d1d;
}

h2 {
	text-align: left;
	font-size: 18px;
	line-height: 27px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #1d1d1d;
}

h3 {
	text-align: left;
	font-size: 16px;
	line-height: 26px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #1d1d1d;
}

/* download button css */
.common-container .download-container {
	width: 35px;
	height: 35px;
	background: #0055af 0% 0% no-repeat padding-box;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.common-container .download-container.disabled {
	cursor: none;
	pointer-events: none;
	background: #c8ced7 0% 0% no-repeat padding-box;
}

.common-container .download-container svg {
	height: 20px;
	width: 20px;
	fill: #fff;
}

.common-container .download-container svg.disabled {
	pointer-events: none;
}

.Mui-focused fieldset {
	border: none !important;
	outline: none !important;
}

.maintenance-container h2 {
	text-align: center;
}

.maintenance-container .maintenance-image-wrapper {
	display: flex;
	margin-left: 12em;
}

.maintenance-container .maintenance-image-wrapper img {
	height: 70%;
	width: 70%;
}

.multiple-action-dropdown-container {
	height: 35px;
	width: 35px;
}

.dropdown-options {
	box-sizing: border-box;
	box-shadow: 0 5px 13px -3px #e0e3e7;
	border: 1px solid #cdd2d7;
	border-radius: 4px;
	position: relative;
	display: block;
	z-index: 999;
	background: white;
	/* height: 146px; */
	width: 250px;
	top: 8px;
	right: 212px;
}

.calendar-view .dropdown-options {
	/* height: 110px; */
}

.dropdown-options ul.dropdown-options-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.dropdown-options li {
	padding: 5px 10px;
	text-align: left;
	font: normal normal normal 14px/26px Poppins;
	letter-spacing: 0px;
	color: #1d1d1d;
}

.dropdown-options li:hover {
	background: rgba(0, 85, 175, 0.1607843137);
	cursor: pointer;
}

.MuiPaper-root.MuiAccordion-root::before {
	opacity: 0 !important;
}
