body {
	margin: 0;
	font-family: Poppins, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.modalOpen {
	overflow: hidden;
}

code {
	font-family: Poppins !important;
}

.no-table-data p {
	text-align: center;
	font-size: 0.9rem;
	text-align: center;
}
