// Loader component styles
.loader-fullscreen {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1399;
	display: flex;
	height: 100%;
	width: 100%;
	background-color: rgba(57, 73, 96, 0.6);
	&.loader-with-message {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		background-color: rgba(57, 73, 96, 0.6);;
		.loader-message {
			position: absolute;
			top: 0;
			bottom: 0;
			height: 30px;
			margin: auto;
			padding-top: 70px;
			// font-family: $primary-bold;
			font-size: 16px;
			text-align: center;
			color: rgba(255, 255, 255, 0.788);
		}
	}
	.loader {
		margin: auto;
	}
}
.loader,
.loader:after {
	border-radius: 50%;
	width: 7em;
	height: 7em;
}
.loader {
	margin: 60px auto;
	font-size: 8px;
	position: relative;
	text-indent: -9999em;
	border-top: 0.8em solid rgba(255, 255, 255, 0.9);
	border-right: 0.8em solid rgba(255, 255, 255, 0.9);
	border-bottom: 0.8em solid rgba(255, 255, 255, 0.9);
	border-left: 0.8em solid rgba(0, 85, 175, 1);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
